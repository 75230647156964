/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import TitleDefault from 'components/elements/TitleDefault'
import HeroImage from 'components/elements/HeroImage'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import AgendaBlock from 'components/AgendaBlock'

const Sidebar = styled.div`
  position: relative;
`

const StyledContent = styled(ParseContent)`
  & a {
    color: ${props => props.theme.color.text.contrast};
    font-weight: bold;
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <HeroImage fields={acf.banner} />

      <div className="container py-lg-5">
        <div className="row py-5">
          <div className="col-lg-7">
            <TitleDefault>{acf.content.title}</TitleDefault>
            <StyledContent content={acf.content.description} />
          </div>
          <div className="col-lg-1 d-none d-lg-block" />
          <Sidebar className="col-lg-4 px-3">
            <AgendaBlock />
          </Sidebar>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }
        content {
          title
          description
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
